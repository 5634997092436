@use "variables";
.sd-navbar-custom {
  height: 132px;
  font-family: Proxima Nova Alt;
  // top: 0px;
  // z-index: 3000;
  .dropdown-item {
    font-size: 12px;
    padding: 7px 15px;
    color: black !important;
    // color: black !important;
    &:hover {
      background-color: rgba(240, 239, 239, 0.774);
    }
    display: block;
    white-space: break-spaces;
  }
  .sd-top-nav {
    height: 90px;
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
    background-color: variables.$secondaryColor;
    justify-content: space-between;
    .sd-top-nav-left {
      color: variables.$topNavTextColor;
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 10px 0px;
      cursor: pointer;
    }
    .sd-top-nav-right {
      display: flex;
      width: 52%;
      height: 100%;
      flex-direction: column;
      align-items: flex-end;
      .sd-tnl-top {
        display: flex;
        margin-bottom: 10px;
        justify-content: flex-end;
        width: 100%;
        padding: 5px 0px;
        .social-handles {
          img {
            height: 20px;
            margin: 0px 5px;
            cursor: pointer;
            filter: brightness(0);
            &:hover {
              filter: brightness(100);
            }
          }
        }
        .sd-inner-div {
          border-right: 1px solid variables.$navElementBorderColor;
          border-left: 1px solid variables.$navElementBorderColor;
          font-size: 13px;
          font-weight: 400;
          color: variables.$topNavTextColor;
          height: 100%;
          align-items: center;
          display: flex;
          justify-content: center;
          letter-spacing: 0.5px;
          cursor: pointer;
          div {
            display: flex;
            align-items: center;
          }
          &:hover {
            background-color: variables.$navElementHoverColor;
            color: white;
          }
          img {
            margin-right: 6px;
            height: 14px;
            cursor: pointer;
          }
          p {
            text-decoration: none;
          }
        }
        .sd-w-22 {
          width: 32%;
        }
        .sd-w-18 {
          width: 18%;
        }
        .sd-w-17 {
          width: 17%;
        }
        .sd-w-24 {
          width: auto;
          padding: 0px 14.5px;
        }
        .sd-w-25 {
          width: 25%;
        }

        .dropdown {
          width: 18%;
          &:hover {
            background-color: variables.$navElementHoverColor;
          }
          button {
            width: 100%;
            white-space: nowrap;
            align-items: center;
            display: flex;
            justify-content: center;
            height: 100%;
            font-size: 11px;
            letter-spacing: 0.5px;
            font-weight: 400;
            color: variables.$topNavTextColor;
            outline: none;
            cursor: pointer;
            border-right: 1px solid #44469f;
            border-left: 1px solid #44469f;
            box-shadow: none;
            border-radius: 3px;
            img {
              height: 7px;
              margin-left: 6px;
              margin-top: 2px;
            }
          }
          .dropdown-menu {
            width: 100%;
            margin-left: -5%;
            p {
              font-size: 12px;
              padding: 7px 15px;
              margin-bottom: 0;
              color: #181818 !important;
              // color: black !important;
              &:hover {
                background-color: rgba(240, 239, 239, 0.774);
              }
              display: block;
              white-space: break-spaces;
            }
          }
        }
        .sd-dropdown-language {
          position: relative;
          width: auto;
          padding: 0px 14.5px;
          margin-left: 10px;
          display: inline-block;
          border-left: 1px solid #956a33;
          &:hover .sd-dropdown-content-language {
            display: flex;
          }
          &:hover {
            background-color: variables.$navElementHoverColor;
            color: white;
          }
          .sd-dropbtn-language {
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            height: 100%;
            font-size: 13px;
            letter-spacing: 0.5px;
            font-weight: 400;
            color: variables.$topNavTextColor;
            outline: none;
            cursor: pointer;
            // border-right: 1px solid #44469f;
            // border-left: 1px solid #44469f;
            border: none;
            box-shadow: none;
            border-radius: 0px;
            background-color: transparent;
            img {
              height: 7px;
              margin-left: 6px;
              margin-top: 2px;
              filter: brightness(0);
            }
            &:hover {
              color: white;
              img {
                filter: brightness(100);
              }
            }
          }
          .sd-dropdown-content-language {
            display: none;
            flex-direction: column;
            position: absolute;
            background-color: white;
            min-width: 140px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 49;
            margin-top: 0.1vh;
            margin-left: -15px;
            color: black !important;
            border-radius: 3px;
            padding: 4px 0px;
            p {
              width: 100%;
              font-size: 13px;
              padding: 4px 10px;
              color: #181818;
              text-decoration: none;
              font-weight: 400;
              cursor: pointer;
              &:hover {
                background-color: rgb(240, 239, 239);
              }
            }
          }
        }
        .sd-selected {
          background-color: variables.$navElementHoverColor !important;
        }
      }
      .sd-tnl-bottom {
        display: flex;
        flex-direction: flex-end;
        width: 100%;
        .sd-tnlb-top {
          background-color: white;
          padding: 0px 10px;
          border-radius: 6px;
          height: 80%;
          margin-right: 20px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // z-index: 49;
          input {
            border: none;
            height: 100%;
            width: 80%;
            color: black;
            &::placeholder {
              color: black;
            }
            &:focus {
              border: none;
              box-shadow: none;
              outline: none;
            }
          }
          img {
            filter: brightness(0);
            height: 50%;
          }
        }
        .sd-tnlb-button {
          height: 90%;
          font-size: 13px;
          background-color: #ce5524;
          color: white;
          border: none;
          box-shadow: none;
          white-space: nowrap;
          border-radius: 0;
          font-weight: 400;
          margin: 0px;
          border-radius: 6px;
          height: 80%;
          letter-spacing: 0.2px;
          padding: 0px 10px;
          display: flex;
          align-items: center;
          &:hover {
            background-color: variables.$navElementHoverColor;
          }
          img {
            height: 17px;
            margin-right: 3px;
          }
          .sd-dropdown-signin {
            margin-left: 3px;
            position: relative;
            width: 100%;
            display: inline-block;
            text-align: center;
            &:hover .sd-dropdown-content-signin {
              display: flex;
            }
            &:hover {
              background-color: variables.$navElementHoverColor;
            }
            img {
              padding-left: 10px;
            }
            .sd-dropbtn-signin {
              text-transform: capitalize;
              width: auto;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-align: center;
              height: 100%;
              font-size: 11px;
              letter-spacing: 0.5px;
              font-weight: 400;
              color: white;
              outline: none;
              cursor: pointer;
              border: none;
              box-shadow: none;
              border-radius: 0px;
              background-color: transparent;
              .spcl {
                height: 16px;
                margin: 0px;
                padding-left: 0px;
                margin-right: 5px;
              }
              img {
                height: 7px;
                margin-left: 6px;
                margin-top: 2px;
              }
            }
            .sd-dropdown-content-signin {
              display: none;
              flex-direction: column;
              position: absolute;
              background-color: white;
              min-width: 160px;
              box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
              z-index: 49;

              margin-top: 0.1vh;
              // margin-left: -64px;
              right: 0;
              text-align: left;
              color: black !important;
              border-radius: 3px;
              padding: 0.5vw 0px;
              p {
                width: 100%;
                margin-bottom: 0;
                font-size: 13px;
                padding: 5px 15px;
                color: #181818;
                text-decoration: none;
                font-weight: 500;
                cursor: pointer;
                &:hover {
                  background-color: rgb(240, 239, 239);
                }
              }
            }
          }
        }
      }
    }
  }

  .sd-bottom-nav {
    height: 42px;
    display: flex;
    padding-left: 50px;
    padding-right: 50px;
    background-color: variables.$baseColor;

    .sd-inner-navbar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sd-hamburger-icon {
        display: none;

        div {
          width: 30px;
          height: 3px;
          background-color: white;
          margin: 6px 0;
        }
      }
      .mobile-logo {
        display: none;
      }
      .sd-user-slider {
        display: none;
      }
      .sd-left-sider {
        display: none;
      }
      .sd-right-sider {
        display: none;
      }
      .sd-inner-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
          height: 110px;
          margin-bottom: 35px;
          cursor: pointer;
        }
        p {
          font-size: 19px;
          line-height: 22px;
          font-weight: 400;
          margin-left: 10px;
          letter-spacing: 0.4px;
          text-decoration: none;
          cursor: pointer;
          color: variables.$bottomNavtextColor;
        }
      }
      .sd-inner-right {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 0px;
        width: 100%;
        height: 100%;
        .sd-top-list {
          padding-right: 0px;
          display: flex;
          justify-content: flex-end;
          margin-bottom: 0px;
          list-style: none;
          text-align: right;
          align-items: center;

          .item {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
              display: flex;
              align-items: center;
              font-weight: 400;
              color: variables.$bottomNavtextColor;
              font-size: 15px;
              text-decoration: none;
              padding: 10px 15px;
              height: 100%;
              text-align: right;

              img {
                height: 18px;
              }
              &:hover {
                background-color: variables.$navElementHoverColor;
              }
            }
            img {
              height: 18px;
              padding: 0px 15px;
              // padding: 2px 10px 4px;
            }
          }
          .sd-spcl {
            img {
              padding-right: 0px;
            }
          }
        }
        .sd-bottom-list {
          padding-bottom: 0px;
          display: flex;
          margin-bottom: 0px;
          list-style: none;
          align-items: center;
          padding-left: 0;

          .item {
            align-self: center;
            p {
              font-weight: 400;
              color: variables.$bottomNavtextColor;
              padding: 2px 15px 4px;
              letter-spacing: 0.2px;
              font-size: 15px;
              text-decoration: none;
            }
            &:hover {
              background-color: variables.$navElementHoverColor;
            }
          }
          .border-r {
            align-self: center;
          }
          .sd-first-button {
            align-self: center;
            height: 100%;
            font-size: 15px;
            display: flex;
            white-space: nowrap;
            // &:first-child > button{
            //   padding:  6px 6px 6px 0px;
            //   margin-left: 0px;
            // }
            .dropdown-menu {
              display: none;
              width: 210px;
              margin-top: -0.1vh;
              color: variables.$bottomNavtextColor !important;

              p {
                font-size: 13px;
                padding: 6px 13px;
                margin-bottom: 0;
                color: variables.$bottomNavtextColor;
                text-decoration: none;
                font-weight: 400;
                cursor: pointer;
                &:hover {
                  background-color: rgb(240, 239, 239);
                }
                &:hover > .side-hover {
                  display: block;
                }

                .side-hover {
                  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.2);
                  display: none;
                  padding-top: 1vh;
                  padding-bottom: 1vh;

                  margin-left: 82%;
                  background-color: white;
                  position: absolute;
                  min-width: 100%;
                  margin-top: -17%;
                  div {
                    font-size: 13px;
                    line-height: 16px;
                    padding: 8px 15px;
                    color: variables.$bottomNavtextColor;
                    &:hover {
                      background-color: rgb(240, 239, 239);
                    }
                    p {
                      color: variables.$bottomNavtextColor !important;
                      padding-left: 0vw;
                      text-decoration: none;
                      &:hover {
                        background-color: rgb(240, 239, 239);
                      }
                    }
                  }
                }
                .sd-pra {
                  max-width: 270px;
                  width: 270px;
                }
                .sd-par {
                  // max-width: 200px;
                  // width: 280px;
                }
                .sd-left-hover {
                  margin-right: 0%;
                  margin-left: -105%;
                }
              }
            }
            .sd-media {
              max-width: 140px;
            }

            .sd-left {
              margin-left: -85px !important;
              width: 170px !important;
            }

            &:hover {
              background-color: variables.$navElementHoverColor;
            }
            &:hover > .dropdown-menu {
              display: flex !important;
              flex-direction: column;
            }
            .active {
              background-color: #40102e;
            }
            button {
              height: 100%;
              font-size: 15px;
              background-color: variables.$baseColor;
              color: variables.$bottomNavtextColor;
              border: none;
              box-shadow: none;
              white-space: nowrap;
              border-radius: 0;
              font-weight: 400;
              margin: 0px;
              padding: 2px 15px 4px;
              letter-spacing: 0.2px;
              &:hover {
                background-color: variables.$navElementHoverColor;
              }
            }
          }

          .sd-dropdown {
            position: relative;
            display: inline-block;

            &:hover .sd-dropdown-content {
              display: flex;
              flex-direction: column;
            }
            &:hover {
              background-color: variables.$navElementHoverColor;
            }
            .sd-dropbtn {
              height: 80%;
              font-size: 15px;
              background-color: #ffaf42;
              color: variables.$bottomNavtextColor;
              border: none;
              box-shadow: none;
              font-weight: 400;
              margin: 0px;
              padding: 2px 15px 4px;
              letter-spacing: 0.2px;
              &:hover {
                background-color: variables.$navElementHoverColor;
              }
            }
            .sd-dropdown-content {
              display: none;
              position: absolute;
              background-color: white;
              min-width: 160px;
              box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
              z-index: 49;

              margin-top: -0.1vh;
              color: variables.$bottomNavtextColor;
              border-radius: 3px;
              padding: 0.5vw 0px;

              p {
                font-size: 13px;
                padding: 7px 15px;
                color: variables.$bottomNavtextColor;
                text-decoration: none;
                font-weight: 400;
                cursor: pointer;
                &:hover {
                  background-color: rgb(240, 239, 239);
                }
              }
            }
            .active {
              background-color: #40102e;
            }
            &:hover > .sd-dropbtn {
              background-color: #40102e;
            }
          }

          .active {
            background-color: #40102e;
          }
        }
      }
    }
  }
  .active-r {
    height: 50px;
    background-color: white;
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 42px -3px grey;
    position: absolute;
    z-index: 49;
    border-radius: 50%;
    padding: 15px;
    margin: 80px 30px;
    bottom: 0 !important;
    right: 0 !important;
    transform: display 1s;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 13px;
      transform: rotate(180deg);
      filter: invert(1);
    }
  }
  .hide-r {
    display: none;
  }
  #tms-overlay {
    display: none;
    position: absolute;
    width: 100vw;
    height: calc(100vh - 132px);
    background-color: #efefef;
    z-index: 20;
    box-shadow: 0px 0px 200px 60px black;
    .sd-t1-slider {
      // padding: 33px 33px 20px;
      padding: 30px 60px 5px 60px;
      background-color: #efefef;

      overflow: hidden;
      display: flex;
      width: 100%;
      .temples-div {
        height: auto;
        max-height: 100%;
        width: 8.5%;
        min-width: 8.5%;
        margin-right: 0.75%;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 5px;
        // padding: 10px;
        background-color: #efefef;
        &:hover img {
          // width: 91%;

          box-shadow: 0px 0px 20px 6px #f7a457;
        }
        img {
          width: 90%;
          border-radius: 16px;
        }
        p {
          text-align: center;
          margin: 5px 0px 0px -5px;
          line-height: 18px;
          font-size: 13px;
          color: #000000;
        }
      }
    }
    .temple-list {
      padding: 0 60px;
      h2 {
        font-size: 24px;
        margin-bottom: 0px;
      }
      hr {
        border-top: 1px solid #cfcfcf !important;
      }
      .temples {
        display: flex;
        justify-content: space-between;
        button {
          background-color: variables.$buttonBackgroundColor;
          border-radius: 5px;
          padding: 6px 13px;
          color: white;
          border: none;
        }
      }
      .temple-list-names {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        p {
          display: flex;
          align-items: center;
          width: 50%;
          line-height: 18px;
          font-size: 13px;
          color: #999999;
          span {
            height: 5px;
            width: 5px;
            background-color: #000000;
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .block {
    display: block;
  }
}

@media screen and (min-width: 1800px) {
  .sd-navbar-custom {
    .sd-top-nav {
      .sd-top-nav-right {
        .sd-inner-div {
          .sd-dropdown-signin {
            .sd-dropdown-content-signin {
              margin-left: -70px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .sd-navbar-custom {
    .sd-top-nav {
      .sd-top-nav-right {
        .sd-inner-div {
          .sd-dropdown-signin {
            .sd-dropdown-content-signin {
              // margin-left: -77.5px;
              min-width: 100%;
            }
          }
        }
      }
    }
    #tms-overlay {
      .sd-t1-slider {
        .temples-div {
          p {
            font-size: 15px;
          }
        }
        .slick-slider {
          .slick-dots {
            bottom: -20px;
            li {
              margin: 0 1px;
              color: variables.$headingTextColor;
              button {
                opacity: 1;
                &::before {
                  color: variables.$headingTextColor;
                  font-size: 10px;

                  opacity: 1;
                }
              }
            }
            .slick-active {
              button {
                &::before {
                  color: variables.$secondaryColor;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1300px) and (min-width: 900px) {
  .sd-navbar-custom {
    height: 132px;
    .sd-top-nav {
      .sd-top-nav-right {
        width: 60%;
      }
    }
    .sd-bottom-nav {
      .sd-inner-navbar {
        .sd-inner-right {
          .sd-top-list {
            .item {
              p {
                font-size: 12.5px;
              }
            }
          }
          .sd-bottom-list {
            .sd-first-button {
              font-size: 12px;
              &:first-child > button {
                padding-left: 0px;
              }
              button {
                font-size: 12.5px;
              }
            }
          }
        }
      }
    }
    #tms-overlay {
      .sd-t1-slider {
        .temples-div {
          p {
            font-size: 11.5px;
          }
        }
        .slick-slider {
          .slick-dots {
            bottom: -20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) and (min-width: 769px) {
  .sd-navbar-custom {
    height: 132px;
    .sd-top-nav {
      padding-left: 40px;
      padding-right: 40px;
      .sd-top-nav-right {
        width: 60%;
      }
    }
    .sd-bottom-nav {
      padding-left: 40px;
      padding-right: 40px;
      .sd-inner-navbar {
        .sd-inner-right {
          .sd-top-list {
            .item {
              p {
                font-size: 11px;
              }
            }
          }
          .sd-bottom-list {
            .sd-first-button {
              font-size: 11px;
              &:first-child > button {
                padding-left: 0px;
              }
              button {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
    #tms-overlay {
      .sd-t1-slider {
        padding: 15px;
        flex-wrap: wrap;
        justify-content: center;
        .temples-div {
          width: 13% !important;
          min-width: 13%;
          p {
            font-size: 12px;
          }
        }
        .slick-slider {
        }
      }
    }
  }
}

@media screen and (max-width: 769px) and (min-width: 100px) {
  .sd-navbar-custom {
    height: 60px;
    .sd-top-nav {
      display: none;
      padding: 0px;
      .sd-top-nav-right {
        width: 100%;
        .sd-inner-div {
          font-size: 7.5px;

          img {
            margin-right: 6px;
            height: 10px;
            cursor: pointer;
          }
          p {
            text-decoration: none;
          }
        }

        .dropdown {
          width: 18%;
          &:hover {
            background-color: variables.$navElementHoverColor !important;
          }
          button {
            font-size: 7.5px;
            img {
              height: 6px;
              margin-left: 6px;
              margin-top: 2px;
            }
          }
          .dropdown-menu {
            width: 100%;
            margin-left: -5%;
            p {
              font-size: 11px;
              padding: 10px;
              padding-left: 30px;
              color: variables.$bottomNavtextColor !important;
              margin-bottom: 0;
            }
          }
        }
        .sd-selected {
          background-color: variables.$navElementHoverColor !important;
        }
      }
    }
    .sd-bottom-nav {
      height: 100%;
      padding: 0px;
      .slide-nav {
        display: block;
      }
      .sd-inner-navbar {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        .sd-hamburger-icon {
          cursor: pointer;
          display: block;
          div {
            height: 2px;
          }
        }
        .mobile-logo {
          display: block;
        }
        .none {
          display: none;
        }
        .block {
          display: block;
        }
        .sd-left-sider {
          width: 100vw;
          height: 100vh;
          position: fixed;
          background-color: white;
          left: 0;
          top: 0;
          z-index: 51;
          // transform: translate3d(0, 0, 0);
          animation: slide-from-left 0.5s;
          // overflow: auto;
          box-sizing: border-box;
          // -webkit-overflow-scrolling: touch;
          .inner {
            height: 100%;
            text-align: center;
            overflow: scroll;
            -webkit-overflow-scrolling: touch;

            // animation: slide-from-right 0.5s;

            .inner-nav-top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: variables.$secondaryColor !important;
              height: 9%;
              .left {
                display: flex;
                padding-left: 5px;
                p {
                  text-decoration: none;
                  color: variables.$bottomNavtextColor !important;
                  text-align: left;
                  font-size: 15px;
                }
                img {
                  padding: 8px;
                  height: 45px;
                  width: 45px;

                  align-self: center;
                }
              }
              .right {
                width: 52%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .dropdown {
                  background-color: variables.$footerMiddleBackground;
                  button {
                    font-size: 12px;
                    padding: 6px 13px;
                    border-radius: 6px;
                    font-weight: 400;
                    align-items: center;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    color: #ffffff;
                    img {
                      padding-left: 8px;
                      height: 8px;
                      padding-right: 0px;
                      color: #ffffff;
                      // filter: invert(1);
                    }
                  }
                  .dropdown-menu {
                    width: 100%;
                    margin-left: -5%;
                    p {
                      font-size: 13px;
                      padding: 10px;
                      padding-left: 30px;
                      color: variables.$bottomNavtextColor !important;
                      font-weight: 500;
                      margin-bottom: 0;
                    }
                  }
                }
                img {
                  height: 18px;
                  padding-left: 18px;
                  padding-right: 18px;
                }
                button {
                  height: 46%;
                  border: none;
                  border-left: 1px solid #ffffff;
                  display: flex;
                  font-size: 10px;
                  padding: 18px;
                  background-color: transparent;
                  span {
                    font-size: 40px;
                    color: white !important;
                    margin-bottom: -3px;
                    align-self: center;
                  }
                }
              }
            }
            .inner-bottom {
              height: 85%;
              &::-webkit-scrollbar {
                display: none;
              }
              .inner-bottom-t {
                padding: 8px 4px;
                div {
                  color: white;
                  text-align: left;
                  padding: 10px;
                  margin-left: 20px;
                  font-weight: 400;
                  font-size: 14px;

                  img {
                    height: 18px;
                    margin-right: 10px;
                  }
                }
                background-color: variables.$footerMiddleBackground;
              }
              .inner-bottom-b {
                margin: 0px 20px;
                padding: 8px 4px;
                padding-bottom: 50px;

                div {
                  // background: antiquewhite;
                  padding: 10px;
                  color: variables.$navElementHoverColor;
                  font-weight: 400;
                  text-align: left;
                  font-size: 16px;
                  width: 100%;
                  cursor: pointer;

                  img {
                    height: 18px;
                    margin-right: 5px;
                  }
                }
                .drop-one {
                  background: variables.$mobileNavElementBackground;
                  padding-left: 0px;
                  padding-right: 0px;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;

                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 20px;
                    }
                  }
                  .drop-11 {
                    background: variables.$mobileNavInnerElementBackground;
                    width: 100%;
                    margin-left: -0px;
                    // padding-right: 20px;
                    div {
                      // margin-left: 35px;
                      // margin-right: 35px;
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .drop-12 {
                    background: variables.$mobileNavInnerElementBackground;
                    margin-left: -0px;

                    div {
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .block {
                    display: block;
                  }
                  .none {
                    display: none;
                  }
                }
                .drop-two {
                  background: variables.$mobileNavInnerElementBackground;
                  div {
                    margin-left: 15px;
                    // margin-right: 35px;
                    flex-wrap: wrap;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    // background: rgb(252, 209, 157);
                    padding: 8px;
                    text-align: left;
                    font-weight: 400;
                    width: 100%;
                    line-height: 18px;
                    cursor: pointer;
                    img {
                      height: 18px;
                      margin-right: 25px;
                    }
                  }
                }
                .drop-three {
                  background: variables.$mobileNavElementBackground;
                  padding: 0px;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;
                    padding-right: -20px !important;
                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 20px;
                    }
                  }
                  .drop-8 {
                    background: variables.$mobileNavInnerElementBackground;
                    width: 100%;
                    margin-left: -0px;
                    // padding-right: 20px;
                    div {
                      // margin-left: 35px;
                      // margin-right: 35px;
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .drop-9 {
                    background: variables.$mobileNavInnerElementBackground;
                    margin-left: -0px;

                    div {
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .drop-10 {
                    background: variables.$mobileNavInnerElementBackground;
                    margin-left: -0px;

                    div {
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .block {
                    display: block;
                  }
                  .none {
                    display: none;
                  }
                }
                .drop-four {
                  background: variables.$mobileNavElementBackground;
                  padding-left: 0px;
                  padding-right: 0px;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;

                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 11px;
                    }
                  }
                }
                .drop-five {
                  background: variables.$mobileNavElementBackground;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;

                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 11px;
                    }
                  }
                }
                .drop-six {
                  background: variables.$mobileNavElementBackground;
                  padding: 0px;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;
                    padding-right: -20px !important;
                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 20px;
                    }
                  }
                  .drop-13 {
                    background: variables.$mobileNavInnerElementBackground;
                    width: 100%;
                    margin-left: -0px;
                    // padding-right: 20px;
                    div {
                      // margin-left: 35px;
                      // margin-right: 35px;
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .drop-14 {
                    background: variables.$mobileNavInnerElementBackground;
                    margin-left: -0px;

                    div {
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .drop-15 {
                    background: variables.$mobileNavInnerElementBackground;
                    margin-left: -0px;

                    div {
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .block {
                    display: block;
                  }
                  .none {
                    display: none;
                  }
                }
                .drop-seven {
                  background: variables.$mobileNavElementBackground;
                  padding-left: 0px;
                  padding-right: 0px;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;

                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 11px;
                    }
                  }
                }
                .between {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }
              }
            }
            .inner-footer {
              height: 6%;
              width: 100%;
              background-color: variables.$headingTextColor;
              position: absolute;
              display: flex;
              bottom: 0;
              justify-content: center;
              align-items: center;
              img {
                height: 25px;
                width: 25px;
                padding: 3px;
              }
            }
          }
          .inner-bottom {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }

          .close {
            background: rgb(243, 212, 172);
            padding: 10px;
            text-align: center;
            width: 100%;
            margin-bottom: 20px;
            font-size: 5vw;
            cursor: pointer;
          }
        }
        .sd-right-sider {
          width: 100vw;
          height: 100vh;
          position: absolute;
          background-color: white;
          left: 0;
          top: 0;
          z-index: 51;
          animation: slide-from-right 0.5s;
          overflow: hidden;
          box-sizing: border-box;
          .inner {
            height: 100%;
            text-align: center;
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
            .inner-nav-top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: variables.$secondaryColor;
              height: 50px;
              .left {
                display: flex;
                padding-left: 5px;
                p {
                  text-decoration: none;
                  color: white;
                  text-align: left;
                  font-size: 15px;
                }
                img {
                  padding: 8px;
                  width: 50px;
                  height: 50px;

                  align-self: center;
                }
              }
              .right {
                width: 52%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .dropdown {
                  // padding-left: 15px;
                  // padding-right: 15px;
                  // height: 80%;
                  background-color: variables.$mobileNavElementBackground;
                  border-radius: 4px;

                  button {
                    font-size: 12px;
                    padding: 6px 13px;
                    border-radius: 6px;
                    font-weight: 400;
                    align-items: center;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    img {
                      padding-left: 8px;
                      height: 8px;
                      padding-right: 0px;
                      color: #181818;
                      filter: invert(1);
                    }
                  }
                  .dropdown-menu {
                    width: 100%;
                    margin-left: -5%;
                    p {
                      font-size: 13px;
                      padding: 10px;
                      padding-left: 30px;
                      color: variables.$bottomNavtextColor !important;
                      font-weight: 500;
                      margin-bottom: 0;
                    }
                  }
                }
                img {
                  height: 18px;
                  padding-left: 18px;
                  padding-right: 18px;
                }
                button {
                  height: 46%;
                  border: none;
                  border-left: 1px solid grey;
                  display: flex;
                  font-size: 10px;
                  padding: 18px;
                  background-color: transparent;
                  span {
                    font-size: 50px;
                    color: white;
                    // margin-bottom: 8px;
                    align-self: center;
                  }
                }
              }
            }
            .inner-bottom {
              height: 85%;
              &::-webkit-scrollbar {
                display: none;
              }
              .inner-bottom-t {
                padding: 8px 4px;
                text-align: left;
                color: white;
                img {
                  height: 100px;
                  padding: 20px;
                }
                div {
                  color: WHITE;
                  text-align: left;
                  padding: 10px;
                  margin-left: 20px;
                  font-weight: 400;
                  font-size: 14px;
                }
                background-color: variables.$footerMiddleBackground;
              }
              .inner-bottom-b {
                margin: 0px 20px;
                padding: 8px 4px;
                padding-bottom: 50px;

                div {
                  padding: 10px;
                  color: variables.$navElementHoverColor;
                  font-weight: 400;
                  text-align: left;
                  font-size: 16px;
                  width: 100%;
                  cursor: pointer;

                  img {
                    height: 18px;
                    margin-right: 5px;
                  }
                }

                .drop-one {
                  background: variables.$mobileNavElementBackground;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;

                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 11px;
                    }
                  }
                }
                .drop-right-one {
                  background: variables.$mobileNavElementBackground;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;

                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 11px;
                    }
                  }
                }
                .drop-two {
                  background: variables.$mobileNavInnerElementBackground;
                  div {
                    margin-left: 35px;
                    margin-right: 35px;
                    flex-wrap: wrap;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    // background: rgb(252, 209, 157);
                    padding: 8px;
                    text-align: left;
                    font-weight: 400;
                    width: 100%;
                    line-height: 18px;
                    cursor: pointer;
                    img {
                      height: 18px;
                      margin-right: 25px;
                    }
                  }
                }
                .between {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .sd-padding-none {
                    padding: 0px;
                    img {
                      height: 18px;
                      padding-right: 0px;
                    }
                  }
                }
              }
            }
            .inner-footer {
              height: 6%;
              width: 100%;
              background-color: variables.$headingTextColor;
              position: absolute;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                height: 30px;
                padding: 3px;
              }
            }
          }
          .inner-bottom {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }

          .close {
            background: rgb(243, 212, 172);
            padding: 10px;
            text-align: center;
            width: 100%;
            margin-bottom: 20px;
            font-size: 5vw;
            cursor: pointer;
          }
        }
        .sd-user-slider {
          display: block;
          img {
            height: 25px;
            cursor: pointer;
          }
        }
        .sd-inner-left {
          .logo {
            height: 45px;
            margin-bottom: 0;
          }
          p {
            font-size: 14px;
            line-height: 14px;
            margin-left: 5px;
          }
        }
        .sd-inner-right {
          display: none;
        }
      }
    }
    #tms-overlay {
      height: calc(100vh - 60px);
      overflow-y: scroll;

      .sd-t1-slider {
        padding: 20px 10px;
        padding-bottom: 0px;
        overflow-x: scroll;
        &::-webkit-scrollbar-thumb {
          display: none;
        }
        &::-webkit-scrollbar {
          display: none;
        }
        // flex-wrap: wrap;
        // justify-content: center;
        .temples-div {
          width: 20% !important;
          min-width: 21%;
          margin-left: 1.5%;
          img {
            border-radius: 12px !important;
          }
          p {
            font-size: 13px;
            margin-left: -8px;
          }
        }
        .slick-slider {
          .slick-dots {
            bottom: -18px;
            li {
              margin: 0 0px;
              width: 15px;
              color: #fd5602;
              button {
                opacity: 1;
                &::before {
                  color: #fd5602;
                  opacity: 1;
                  font-size: 8px;
                }
              }
            }
            .slick-active {
              button {
                opacity: 1;
                &::before {
                  color: #ffaf42;
                  opacity: 1;
                  font-size: 8px;
                }
              }
            }
          }
        }
      }
      .temple-list {
        padding: 10px 20px;
        .temples {
          margin-bottom: 15px;
        }
        .temple-list-names {
          p {
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .sd-template-one {
    .sd-t1-slider {
      .temples-div {
        width: 25% !important;
        min-width: 25%;
        margin-left: 1.5%;
        img {
          border-radius: 12px;
        }
        p {
          font-size: 13px;
          margin-left: -8px;
        }
      }
    }
  }
}

@keyframes slide-from-left {
  0% {
    margin-left: -100vw;
  }
  100% {
    margin-left: 0vw;
  }
}

@keyframes slide-from-right {
  0% {
    margin-left: 100vw;
  }
  100% {
    margin-left: 0vw;
  }
}
