.filterAlpha {
  display: flex;
  font-size: 12px;
  margin-bottom: 5px;
  width: 100%;
  flex-wrap: wrap;

  .alpahabets {
    border-radius: 15px;
    opacity: 1;
    border: 1px solid #cccccc;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-top: 2px;
    width: 32px;
    display: flex;
    height: 19px;
    p {
      margin: 0 !important;
      color: black;
      font-weight: 600;
      font-size: 12px;
    }
  }

  .alpahabets:hover {
    background: #8d1c24 0% 0% no-repeat padding-box;
    border: 1px solid #8d1c24;

    p {
      margin: 0 !important;
      color: white !important;
      font-weight: 600;
      font-size: 12px;
    }
  }

  .alpahabetsActive {
    border-radius: 15px;
    opacity: 1;
    background: #8d1c24 0% 0% no-repeat padding-box;
    border: 1px solid #8d1c24;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-top: 2px;
    width: 32px;
    display: flex;
    height: 19px;

    p {
      margin: 0 !important;
      color: white;
      font-weight: 600;
      font-size: 12px;
    }
  }
}
