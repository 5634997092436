@use "variables";
.w-100 {
  width: 100%;
  font-family: "Proxima Nova Alt", sans-serif;
}
.nav-link {
  cursor: pointer;
}
.under {
  text-decoration: underline !important;
  color: #991403;
}
.sd-template-one {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #efefef;
  font-family: "Proxima Nova Alt", sans-serif;
  .center-loader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .sd-t1-slider {
    // padding: 33px 33px 20px;
    padding: 30px 60px 5px 60px;
    background-color: #efefef;

    overflow: hidden;
    display: flex;
    width: 100%;
    .temples-div {
      height: auto;
      max-height: 100%;
      width: 8.5%;
      min-width: 8.5%;
      margin-right: 0.75%;
      box-sizing: border-box;
      cursor: pointer;
      margin-bottom: 5px;
      // padding: 10px;
      background-color: #efefef;
      &:hover img {
        // width: 91%;

        box-shadow: 0px 0px 20px 6px #f7a457;
      }
      img {
        width: 90%;
        border-radius: 16px;
      }
      p {
        text-align: center;
        margin: 5px 0px 0px -5px;
        line-height: 18px;
        font-size: 13px;
        color: #000000;
      }
    }
  }
  hr {
    border: 1px solid #898989;
    opacity: 0.4;
    margin: 0px 45px;
  }
  .sd-t1-slider-caraousel {
    //change

    min-height: 365px;
    padding: 20px 60px 20px 60px;
    overflow: hidden;
    .slick-slider {
      .slick-prev {
        left: 3.5vw;
        z-index: 10;
      }
      .slick-next {
        right: 3.5vw;
        z-index: 10;
      }
      .slick-dots {
        //change
        display: none;
        bottom: -28px !important;
        z-index: 2;
        li {
          margin: 0 1px;
          color: #8d1c24;
          button {
            opacity: 1;
            &::before {
              color: #8d1c24;
              font-size: 15px !important;
              opacity: 1;
            }
          }
        }
        .slick-active {
          button {
            white-space: nowrap;

            &::before {
              color: #ec9140 !important;
              font-size: 15px !important;
            }
          }
        }
      }
      .slick-list {
        .slick-track {
          div {
            border: none;
            box-shadow: none;
            outline: none;
            img {
              border: none;
              box-shadow: none;
              outline: none;
            }
          }
        }
      }
    }
  }
  .sd-fade-in {
    animation: fade-in 1s;
  }
  .sd-t1-bottom {
    height: 55px;
    overflow: hidden;
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
    border-top: 1px solid #d0d0d0;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    .sd-orange {
      width: 12%;
      margin: 0px;
      color: #9a031e;
      font-size: 16px;
      border-right: 1px solid #d8d8d8;
      text-align-last: center;
      font-weight: 800;
      padding-right: 10px;
    }

    .sd-marquee-text {
      font-size: 15px;
      color: #000000 !important;
      width: 78.5%;
      font-weight: 500;
      cursor: pointer;
    }

    div {
      align-items: center;
      text-align: -webkit-center;
      button {
        padding: 6px 13px;
        font-size: 14px;
        font-weight: 500;
        color: white;
        background-color: variables.$buttonBackgroundColor;
        border: none;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        border-radius: 6px;

        img {
          margin-left: 8px;
          height: 18px;
        }
      }
    }
  }
}
.top {
  width: 100%;
  text-align: center;
  color: variables.$headingTextColor;
  // font-weight: 600 !important;
  padding-top: 45px;
  h1 {
    color: variables.$headingTextColor;
    font-size: 30px;
    padding-bottom: 15px;
  }
}
.bc-yellow {
  background: #f5e5e6;
}
.services-bg {
  background-color: variables.$servicesBackground !important;
}
.sd-template-two {
  height: 450px;
  padding: 20px 60px 60px 60px;
  overflow: hidden;
  display: flex;
  font-family: "Proxima Nova Alt", sans-serif;
  background-color: variables.$servicesBackground;

  .sd-box-one {
    padding: 35px 35px 30px 35px;
    width: 23%;
    margin: 1%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .sd-box-one-content {
      height: 75%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      h2 {
        color: black;
        font-weight: 600;
        font-size: 20px;
        margin: 0px;
        text-align: center;
      }
      p {
        line-height: 19px;
        font-size: 15px;
        color: #181818;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
      h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        padding: 5px 0 10px;
        font-size: 15px;
        font-weight: 600;
        color: variables.$buttonBackgroundColor;
        margin-bottom: 0px;
        cursor: pointer;

        img {
          height: 11px;
          margin-left: 5px;
          // margin-top: 2px;
          // filter: invert(1);
        }
      }
      .c-white {
        color: white;
      }
      .c-18 {
        color: #181818;
      }
      button {
        border: none;
        font-size: 14px;
        padding: 6px 13px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        align-content: center;
        outline: none;
        white-space: nowrap;
        border-radius: 3px;
        img {
          height: 11px;
          margin-left: 5px;
        }
        &:hover {
          background-color: variables.$buttonHoverBackgroundColor;
          color: variables.$buttonHoverTextColor;
          img {
            filter: brightness(0%);
          }
        }
      }
      .sd-c-white {
        color: white;
      }
      .sd-c-voilet {
        color: #2f3293;
      }
      .sd-c-orange {
        color: #991403;
      }
      .sd-b-white {
        background-color: white;
      }
      .sd-b-orange {
        background-color: variables.$buttonBackgroundColor;
        border-radius: 6px;

        img {
          height: 20px;
          filter: brightness(100);
        }
      }
    }

    .sd-box-one-image {
      width: 100%;
      height: 25%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .sd-logo-img {
        height: 65px;
        // margin-bottom: 16px;
      }
      .sd-size-one {
        height: 58px;
      }
      .sd-size-two {
        height: 55px;
      }
      .sd-size-three {
        height: 62px;
      }
      .sd-size-four {
        height: 65px;
      }
    }
  }
  .sd-first-color {
    background-color: #460b2f26;
  }
  .sd-second-color {
    background-color: #e3641426;
  }
  .sd-third-color {
    background-color: #9a031e26;
  }
  .sd-fourth-color {
    background-color: #fb8b2426;
  }
}
.sd-tt-right {
  width: 45%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  .sd-box-one {
    padding: 10px;
    width: 50%;
    height: 50%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    position: relative;
    box-sizing: border-box;
    &:nth-child(2n) {
      padding-right: 0;
    }
    &:nth-child(2n + 1) {
      padding-left: 0;
    }
    .sd-box-one-content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      border: 2px solid #dcdcdc;
      padding: 15px;
      border-radius: 12px;

      h2 {
        color: #460b2f;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        margin: 0px;
        text-align: left;
        margin-bottom: 5px !important;
      }
      p {
        line-height: 18px;
        font-size: 14px;
        color: #181818;
        text-align: left;
        overflow: hidden;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3 !important; /* number of lines to show */
        -webkit-box-orient: vertical;
        margin-bottom: 0px !important;
        padding-right: 30px;
      }
      p {
        text-decoration: underline !important;
      }
      h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        padding: 5px 0 10px;
        font-size: 15px;
        // font-weight: 600;
        color: variables.$buttonBackgroundColor;
        margin-bottom: 0px;
        cursor: pointer;

        img {
          height: 11px;
          margin-left: 5px;
          // margin-top: 2px;
          // filter: invert(1);
        }
      }
      .c-white {
        color: white;
      }
      .c-18 {
        color: #181818;
      }
      button {
        border: none;
        font-size: 12px;
        padding: 6px 9px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        // font-weight: 600;
        align-content: center;
        outline: none;
        white-space: nowrap;
        border-radius: 3px;
        img {
          height: 11px;
          margin-left: 5px;
        }
        &:hover {
          background-color: variables.$buttonHoverBackgroundColor;
          color: variables.$buttonHoverTextColor;
          img {
            filter: brightness(0%);
          }
        }
      }
      .sd-c-white {
        color: white;
      }
      .sd-c-voilet {
        color: #2f3293;
      }
      .sd-c-orange {
        color: #991403;
      }
      .sd-b-white {
        background-color: white;
      }
      .sd-b-orange {
        background-color: variables.$buttonBackgroundColor;
        border-radius: 5px;

        img {
          height: 20px;
          // filter: brightness(100);
        }
      }
    }

    .sd-logo-img {
      height: 65px;
      margin-bottom: 16px;
      position: absolute;
      right: 30px;
      bottom: 15px;
    }
    .sd-size-one {
      height: 45px;
    }
    .sd-size-two {
      height: 45px;
    }
    .sd-size-three {
      height: 52px;
      right: 20px;
    }
    .sd-size-four {
      height: 55px;
    }
  }
  .sd-first-color {
    background-color: #fde6d2;
  }
  .sd-second-color {
    background-color: #facda6;
  }
  .sd-third-color {
    background-color: #991403;
  }
  .sd-fourth-color {
    background-color: #2f3293;
  }
}

.sd-template-three {
  height: 500px;
  padding: 80px 60px;
  display: flex;
  justify-content: space-evenly;
  font-family: "Proxima Nova Alt", sans-serif;
  background-color: #fff3e9;
  border-top: 1px solid #fb8b24;

  .sd-t3-one {
    width: 21%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding-right: 10px;
    .nav-circles {
      margin-left: -5px;
    }
    .leftArrow {
      rotate: 180deg;
      filter: grayscale(1);
      cursor: pointer;
    }
    .rightArrow {
      cursor: pointer;
      filter: grayscale(1);
    }
    .leftArrow:hover {
      filter: grayscale(0);
    }
    .rightArrow:hover {
      filter: grayscale(0);
    }
    img {
      max-height: 100%;
    }
    h1 {
      font-size: 36px;
      font-weight: 600;
    }
    p {
      font-size: 19px;
      line-height: 25px;
      color: #2e2c2b;
    }
    button {
      width: fit-content;
      border: none;
      font-size: 14px;
      padding: 6px 13px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      align-content: center;
      outline: none;
      white-space: nowrap;
      border-radius: 3px;
      img {
        height: 11px;
        margin-left: 5px;
      }
      &:hover {
        background-color: variables.$buttonHoverBackgroundColor;
        color: variables.$buttonHoverTextColor;
        img {
          filter: brightness(0%);
        }
      }
    }
    .sd-b-orange {
      background-color: variables.$buttonBackgroundColor;
      border-radius: 10px;
      img {
        height: 20px;
      }
    }
  }

  .sd-t3-three {
    width: 25%;
    height: 100%;

    .sd-box-one {
      width: 100%;
      margin: 1%;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 15px;
      .sd-box-one-content {
        height: 75%;
        display: flex;
        padding: 15px;
        // flex-direction: column;
        justify-content: space-evenly;
        // align-items: center;
        div {
          p {
            padding-left: 5px;
            line-height: 19px;
            font-size: 14px;
            color: #181818;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5; /* number of lines to show */
            -webkit-box-orient: vertical;
          }
        }
        h2 {
          color: black;
          padding-left: 5px;
          font-size: 20px;
          font-weight: 600;
          text-align: left;
        }

        h6 {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          white-space: nowrap;
          padding: 5px 0 10px;
          font-size: 15px;
          // font-weight: 600;
          color: #991403;
          margin-bottom: 0px;
          cursor: pointer;

          img {
            height: 11px;
            margin-left: 5px;
            // margin-top: 2px;
            // filter: invert(1);
          }
        }
        .c-white {
          color: white;
        }
        .c-18 {
          color: #181818;
        }
        button {
          border: none;
          font-size: 14px;
          padding: 6px 13px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          align-content: center;
          outline: none;
          white-space: nowrap;
          border-radius: 3px;
          img {
            height: 11px;
            margin-left: 5px;
          }
          &:hover {
            background-color: variables.$buttonHoverBackgroundColor;
            color: variables.$buttonHoverTextColor;
            img {
              filter: brightness(0%);
            }
          }
        }
        .sd-c-white {
          color: white;
        }
        .sd-c-voilet {
          color: #2f3293;
        }
        .sd-c-orange {
          color: #991403;
        }
        .sd-b-white {
          background-color: white;
        }
        .sd-b-orange {
          background-color: variables.$buttonBackgroundColor;
          border-radius: 10px;

          img {
            height: 20px;
          }
        }
      }

      .sd-box-one-image {
        width: 100%;
        height: 200px;
        border-radius: 0px;
        padding: 0px;
        .sd-logo-img {
          height: 200px;
          border-radius: 0px;
        }
        .sd-size-one {
          height: 68px;
        }
        .sd-size-two {
          height: 55px;
        }
        .sd-size-three {
          height: 62px;
        }
        .sd-size-four {
          width: 100%;
        }
      }
    }
  }
}
.happenings-bg {
  background: variables.$happeningsBackground;
}
.sd-template-four {
  height: 650px;
  padding: 60px 60px 50px 60px;
  display: flex;
  justify-content: space-between;
  font-family: "Proxima Nova Alt", sans-serif;
  background: variables.$happeningsBackground;
  border-radius: 15px 15px 0px 0px;

  .sd-t4-one {
    width: 31%;
    height: calc(100% - 50px);
    background-color: white;
    border-radius: 15px;
    border: 1px solid grey;

    .sd-t4-one-start {
      background-color: variables.$baseColor;
      color: white;
      text-align: center;
      padding: 10px 20px;
      border-radius: 15px 15px 0px 0px;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 16px;
      }
      img {
        height: 25px;
      }
    }
    .sd-t4-one-middle {
      background-color: variables.$secondaryColor;
      color: white;
      text-align: center;
      padding: 10px 20px;
      border-radius: 15px 15px 0px 0px;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 16px;
      }
      img {
        height: 25px;
      }
    }
    .sd-t4-calender {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 45px;
      padding: 5px 20px;
      // background-color: #f5f5f5;
      img {
        height: 20px;
      }
    }
    .element-panchangam-wrapper {
      padding: 0px 15px 10px 15px;
      height: calc(100% - 100px);
      flex-direction: column;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .element-panchangam {
        display: flex;
        justify-content: space-between;
        padding: 12px 0px;
        border-bottom: 1px solid #707070;

        .w-100 {
          width: 100% !important;
        }
        &:last-child {
          border-bottom: none;
          div {
            width: 50% !important;
          }
        }

        div {
          width: 35%;
          display: flex;
          flex-direction: column;
          border-right: 1px solid #707070;
          padding-left: 5px;
          &:last-child {
            border: none;
          }
          &:first-child {
            padding-left: 0px !important;
            width: 25%;
          }
          p {
            margin-bottom: 0px;
            text-align: left;
            font-size: 12px;
          }
          h5 {
            text-align: left;
            font-size: 14px;
          }
        }
      }
    }
    .sd-t4-one-end {
      background-color: variables.$baseColor;
      color: white;
      text-align: center;
      border-radius: 15px 15px 0px 0px;
      display: flex;
      justify-content: space-between;
      height: 45px;
      span {
        padding: 10px 20px;
        font-size: 16px;
        width: 33%;
        border-right: 1px solid #b54233 !important;
        &:last-child {
          border-right: none !important;
        }
        &:hover {
          color: #ffaf42;
          cursor: pointer;
        }
      }
      .span-active {
        border-bottom: 4px solid #8d1c24;
      }
      img {
        height: 25px;
      }
    }
    .sd-t4-one-calender {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 45px;
      font-weight: 600;
      padding: 5px 20px;
      // background-color: #f5f5f5;
      img {
        height: 20px;
      }
    }
    .sd-t4-one-end-d {
      display: flex;
      height: calc(100% - 145px);
      flex-direction: column;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      .element-date {
        display: flex;
        padding: 10px 20px 0px 20px;
        div {
          width: 50px;
          text-align: center;
          border-radius: 10px;
          margin-right: 10px;
          background-color: #ffe9ca;
          padding: 5px;
          height: 45px;
          font-size: 10px;
          span {
            font-size: 17px;
          }
        }
        p {
          width: calc(100% - 50px);

          margin-bottom: 0px;
          font-size: 12px;
        }
      }
    }
    .sd-t4-one-middle-b {
      height: 50px;
    }
    .sd-t4-one-end-m {
      display: flex;
      height: calc(100% - 190px);
      flex-direction: column;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .element-date-m {
        margin: 10px 0px 5px 0px;
        padding: 0pc 20px;
        div {
          text-align: center;
          border-radius: 10px;
          margin-right: 10px;
          border: 1px solid grey;
          padding: 5px;
          width: fit-content;
          font-size: 11px;
          padding: 2px 8px;
        }
        p {
          width: 100%;
          margin: 2px 0px 0px 3px;

          font-size: 14px;
          color: #181818;
        }
      }
    }
    hr {
      border: 0.5px solid #707070;
      border-collapse: collapse;
      margin: 10px 20px 10px 20px !important;
    }
    .view-all {
      margin: 0px 20px 0px;
      font-size: 15px;
      line-height: 12px;
      color: #000000;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      img {
        margin-left: 5px;
      }
    }
  }
}
.sd-template-five {
  padding: 0 60px 30px;
  display: flex;
  flex-direction: column;
  font-family: "Proxima Nova Alt", sans-serif;
  background: rgb(239 239 246);

  h2 {
    align-self: center;
    margin-bottom: 5px;
    color: variables.$headingTextColor;
    font-size: 36px;
    font-weight: 400;
  }
  .item1 {
    grid-area: one;
    height: 100%;
  }
  .item2 {
    grid-area: two;
  }
  .item3 {
    grid-area: three;
  }
  .item4 {
    grid-area: four;
  }
  .item5 {
    grid-area: five;
    // height: 100%;
  }
  .item6 {
    grid-area: six;
    height: 66.5%;
  }
  .item7 {
    grid-area: seven;
  }
  .item8 {
    grid-area: eight;
  }

  .sd-photo-grid {
    width: 100%;
    height: 100%;
    display: grid;
    margin-top: 20px;
    grid-template-areas:
      "one two three"
      "one four five"
      "six seven eight";
    grid-template-columns: 1fr 27.5% 27.5%;
    grid-gap: 20px;
    margin-bottom: 20px;

    div {
      display: flex;
      justify-content: space-between;
    }
    img {
      width: 100%;
    }
  }
  .sd-photo-grid-full {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    place-content: center;
    div {
      width: 22.5%;
      margin: 13px;

      .__react_modal_image__modal_container {
        width: 100vw;
        margin: 0px;
        .__react_modal_image__header {
          background-color: transparent;
          width: 98%;
          .__react_modal_image__caption {
            display: none;
          }
          // display: none;
        }
        .__react_modal_image__modal_content {
          width: 100vw;
          div {
            img {
              width: 70%;
              height: auto !important;
              border-radius: 3px;
            }
          }
        }
      }
      img {
        border-radius: 3px;
        height: 200px;
        width: 100%;
      }
    }
  }
  button {
    margin-top: -60px;
    border: none;
    background-color: variables.$headingTextColor;
    color: white;
    font-size: 12px;
    padding: 6px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    align-self: center;
    white-space: nowrap;
    border-radius: 10px;
    img {
      height: 18px !important;
      margin-left: 5px;
      // filter: brightness(100);
    }
    &:hover {
      background-color: variables.$buttonHoverBackgroundColor;
      color: variables.$buttonHoverTextColor;
      img {
        filter: brightness(0%);
      }
    }
  }
  h4 {
    font-weight: 400;
    font-size: 20px;
  }
}
.sd-template-six {
  padding: 60px 60px 50px 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 700px;
  .sd-ts-bottom {
    margin-top: 60px;
    display: flex;
    border-radius: 8px !important;
    .sd-ts-inner-div {
      padding: 30px;
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 80%;
        max-width: 200px;
        background-color: transparent;
      }
      h2 {
        background-color: transparent;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        padding-top: 15px;
      }
      p {
        background-color: transparent;
        text-align: center;
        font-size: 15px;
        padding-top: 5px;
        line-height: 20px;
      }
    }
    :nth-child(1) {
      background-color: #f1ad6f;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    :nth-child(2) {
      background-color: #f3b782;
    }
    :nth-child(3) {
      background-color: #f5c396;
    }
    :nth-child(4) {
      background-color: #f6ccaa;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .sd-t6-one {
    width: 47%;
    img {
      width: 100%;
      max-height: 100%;
    }
  }
  .sd-t6-two {
    width: 59%;
    padding-left: 30px;
    text-align: center;
    padding-top: 10px;
    div {
      h1 {
        font-size: 30px;
        color: variables.$headingTextColor;
        margin-bottom: 15px !important;
      }
      p {
        font-size: 15px;
        margin-top: 30px;
        padding: 20px;
        padding-bottom: 0px !important;
        line-height: 20px;
        color: #171717;
      }
      h5 {
        font-weight: 400 !important;
        font-size: 13.5px;
        line-height: 18px;
        color: #171717;
        padding: 10px 20px;
      }
      .temples {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        div {
          margin-right: 10px;
          padding-right: 20px;
          border-right: 1px solid #cbcbcb;
          &:last-child {
            border-right: none;
          }
          img {
            padding-right: 5px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1450px) {
  .sd-template-one {
    .sd-t1-slider-caraousel {
      //change

      min-height: 385px;

      .slick-slider {
        .slick-dots {
          bottom: -20px;
          li {
            margin: 0 1px;
            color: #620404;
            button {
              opacity: 1;
              &::before {
                color: #620404;
                font-size: 10px;

                opacity: 1;
              }
            }
          }
          .slick-active {
            button {
              &::before {
                color: #ecbd00;
              }
            }
          }
        }
      }
    }
    .sd-t1-slider {
      .temples-div {
        p {
          font-size: 15px;
        }
      }
      .slick-slider {
        .slick-dots {
          bottom: -20px;
          li {
            margin: 0 1px;
            color: variables.$headingTextColor;
            button {
              opacity: 1;
              &::before {
                color: variables.$headingTextColor;
                font-size: 10px;

                opacity: 1;
              }
            }
          }
          .slick-active {
            button {
              &::before {
                color: variables.$secondaryColor;
              }
            }
          }
        }
      }
    }
    .sd-t1-bottom {
      .sd-orange {
        // font-size: 15.5px;
      }
    }
  }
  .sd-template-two {
    height: 450px;
  }
  .sd-template-three {
    height: 460px;
    .sd-t3-two {
      .sd-box-one {
        padding: 15px 15px;
        .sd-box-one-content {
        }
      }
    }
    .sd-t3-three {
      width: 23%;
      max-width: 300px;
      .sd-box-one {
        padding-bottom: 10px;
      }
    }
  }
  .sd-template-five {
    .sd-photo-grid {
      div {
        margin-bottom: 3.5vh;
      }
      // img {
      //   width: 23.5%;

      //   // margin-bottom: 20px;
      // }
    }
  }
  .sd-template-six {
    height: auto;
    .sd-t6-one {
      img {
        height: auto;
        max-height: auto !important;
      }
    }
    .sd-t6-two {
      div {
        h1 {
          font-size: 30px;
          color: variables.$headingTextColor;
          margin-bottom: 10px;
        }
        p {
          font-size: 16px;
          margin-top: 30px;
          padding: 20px;
          color: #181818;
        }
        h5 {
          font-size: 14px;
          color: #181818;
        }
        .temples {
          div {
            img {
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) and (min-width: 900px) {
  .sd-template-four {
    height: 1000px !important;
    flex-wrap: wrap;
    justify-content: center;

    .sd-t4-one {
      height: 420px !important;
      &:first-child {
        margin-right: 30px !important;
        margin-bottom: 30px;
      }
      width: 45% !important;
      .element-panchangam-wrapper {
        .element-panchangam {
        }
      }
    }
  }
}
@media screen and (max-width: 1100px) and (min-width: 900px) {
  .sd-template-three {
    .sd-t3-one {
      width: 30%;
    }
  }
}
@media screen and (max-width: 1300px) and (min-width: 900px) {
  .sd-template-one {
    .sd-t1-slider {
      .temples-div {
        p {
          font-size: 11.5px;
        }
      }
      .slick-slider {
        .slick-dots {
          bottom: -20px;
        }
      }
    }
    .sd-t1-slider-caraousel {
      //change

      min-height: 220px;

      .slick-slider {
        .slick-dots {
          bottom: -20px;
        }
      }
    }
    .sd-t1-bottom {
      .sd-orange {
        width: auto;
        white-space: pre;
        img {
          height: 20px;
          margin-right: 5px !important;
        }
      }
      .sd-marquee-text {
        width: 75%;
        font-size: 15px;
      }
      div {
        button {
          font-size: 14px;
          padding: 6px 13px;
          // img {
          //   height: 11px;
          //   margin-left: 8px;
          // }
        }
      }
    }
  }
  .sd-template-two {
    height: 400px;
    .sd-tt-left {
      width: 50%;
    }
    .sd-tt-right {
      width: 50%;
      .sd-box-one {
        .sd-logo-img {
          margin-bottom: 5px;
          right: 20px;
        }
      }
    }
    .sd-box-one {
      padding: 25px;
      padding-top: 35px;
      padding-bottom: 20px;
      .sd-box-one-content {
        p {
          // margin-bottom: 0px;
          font-size: 13px;
        }
        button {
          font-size: 12px;
          padding: 6px 13px;
          img {
            height: 8px;
            margin-left: 7px;
          }
        }
        h2 {
          font-size: 17px;
        }
      }
      .sd-box-one-image {
        .sd-logo-img {
          height: 50px;
        }
      }
    }
  }
  .sd-template-three {
    height: 500px;
    justify-content: space-between;
    .sd-t3-two {
      .sd-box-one {
        padding: 10px 10px 10px 10px;
        padding-top: 10px;
        .sd-box-one-content {
          p {
            // margin-bottom: 0px;
            font-size: 13px;
          }
          button {
            font-size: 12px;
            padding: 6px 13px;
            img {
              height: 8px;
              margin-left: 7px;
            }
          }
          h2 {
            font-size: 17px;
          }
        }
        .sd-box-one-image {
          .sd-logo-img {
            height: auto;
          }
        }
      }
    }
    .sd-t3-three {
      width: 33%;
      height: 100%;
      .sd-box-one {
        // padding: 10px;
        height: 100%;
        .sd-box-one-content {
          height: 40%;
          p {
            // margin-bottom: 0px;
            font-size: 13px;
          }
          button {
            font-size: 12px;
            padding: 6px 13px;
            img {
              height: 8px;
              margin-left: 7px;
            }
          }
          h2 {
            font-size: 17px;
          }
        }
        .sd-box-one-image {
          height: 250px;
          .sd-logo-img {
            height: 100%;
          }
        }
      }
    }
    :nth-child(4) {
      display: none;
    }
  }
  .sd-template-four {
    height: 570px;
    .sd-t4-one {
      .sd-t4-one-end {
        span {
          font-size: 14px;
          padding: 10px;
        }
      }
      .element-panchangam-wrapper {
        .element-panchangam {
          div {
            p {
              font-size: 11px !important;
            }
            h5 {
              font-size: 13px !important;
            }
          }
        }
      }
      .sd-t4-one-end-d {
        .element-date {
          div {
            width: 45px;
          }
          p {
            font-size: 12px;
          }
        }
      }
      .sd-t4-one-end-m {
        .element-date-m {
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
  .sd-template-five {
    button {
      font-size: 12px;
      padding: 6px 13px;
      margin-top: 10px;
      img {
        margin: 2px;

        height: 8px;
        margin-left: 7px;
      }
    }
    .sd-photo-grid {
      height: 90%;
      div {
        // flex-wrap: wrap;
        margin-bottom: 0.6vh;
        img {
          margin: 5px;

          // height: 42%;
          // width: 46%;
        }
      }
    }
  }
  .sd-template-six {
    .sd-t6-two {
      div {
        h1 {
          font-size: 30px;
          color: #991403;
          margin-bottom: 10px;
        }
        p {
          font-size: 1.2vw;
          margin-top: 10px;
          padding: 15px;
          color: #171717;
        }
        h5 {
          font-size: 1.1vw;
          line-height: 14px;

          color: #171717;
        }
        .temples {
          div {
            img {
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px) and (min-width: 650px) {
  .sd-template-one {
    .sd-t1-slider {
      padding: 15px;
      flex-wrap: wrap;
      justify-content: center;
      .temples-div {
        width: 13% !important;
        min-width: 13%;
        p {
          font-size: 12px;
        }
        img {
          border-radius: 12px;
        }
      }
      .slick-slider {
        .slick-dots {
          bottom: -13px !important;
          li {
            margin: 0 1px;
            color: #fd5602;
            width: 15px;
            height: 15px;
            button {
              font-size: 5px;
              opacity: 1;
              &::before {
                font-size: 8px;

                color: #fd5602;
                opacity: 1;
              }
            }
          }
          .slick-active {
            button {
              &::before {
                color: #ffaf42;
              }
            }
          }
        }
      }
    }
    .sd-t1-slider-caraousel {
      //change

      padding: 15px;
      min-height: 130px;

      .slick-slider {
        .slick-dots {
          bottom: -13px !important;
          li {
            margin: 0 1px;
            color: #620404;
            width: 15px;
            height: 15px;
            button {
              font-size: 5px;
              opacity: 1;
              &::before {
                font-size: 8px;

                color: #620404;
                opacity: 1;
              }
            }
          }
          .slick-active {
            button {
              &::before {
                color: #ecbd00;
              }
            }
          }
        }
      }
    }
    .sd-t1-bottom {
      height: 40px;
      padding-left: 30px;
      padding-right: 30px;
      .sd-orange {
        width: auto;
        font-size: 12px;
        white-space: pre;
        img {
          height: 15px;
          margin-right: 5px !important;
        }
      }
      .sd-marquee-text {
        width: 75%;
        font-size: 12px;
      }
      div {
        button {
          padding: 4px 8px;
          font-size: 12px;
          img {
            height: 18px;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .sd-template-two {
    height: 280px;
    padding: 30px;
    .sd-box-one {
      padding: 18px;
      .sd-box-one-content {
        p {
          margin-bottom: 5px;
          font-size: 10px;
          line-height: 1.3;
        }
        .sd-b-orange {
          padding: 4px 8px;

          img {
            height: 12px;
          }
        }
        button {
          font-size: 10px;
          padding: 1px 8px;
          img {
            height: 8px;
            margin-left: 5px;
          }
        }
        h2 {
          font-size: 13px;
        }
      }
      .sd-box-one-image {
        .sd-logo-img {
          height: 30px;
          margin-bottom: 0px;
        }
      }
    }
  }
  .sd-template-three {
    height: 700px;
    flex-direction: column;
    align-items: center;
    .sd-t3-one {
      width: 100%;
      align-items: center;
      h1 {
        .hide-t {
          display: none;
        }
        padding-bottom: 10px;
      }
      p {
        text-align: center;
        padding-bottom: 10px;
      }
    }
    .sd-t3-two {
      .sd-box-one {
        padding: 8px;
        .sd-box-one-content {
          .sd-b-orange {
            padding: 4px 8px;

            img {
              height: 12px;
            }
          }
          p {
            font-size: 10px;
            line-height: 1.3;
          }
          button {
            font-size: 10px;
            padding: 1px 8px;
            img {
              height: 8px;
              margin-left: 5px;
            }
          }
          h2 {
            font-size: 13px;
            margin-top: 10px !important;
            margin-bottom: 5px !important;
          }
        }
        .sd-box-one-image {
          .sd-logo-img {
            margin-bottom: 0px;
          }
        }
      }
    }
    .sd-t3-three {
      margin-top: 20px;
      width: 50%;
      min-width: 350px;
      .sd-box-one {
        // padding: 8px;
        .sd-box-one-content {
          p {
            font-size: 10px;
            line-height: 1.3;
          }
          button {
            font-size: 10px;
            padding: 1px 8px;
            img {
              height: 8px;
              margin-left: 5px;
            }
          }
          h2 {
            font-size: 13px;
            margin-top: 10px !important;
            margin-bottom: 5px !important;
          }
          .sd-b-orange {
            padding: 4px 8px;

            img {
              height: 12px;
            }
          }
        }
        .sd-box-one-image {
          height: 300px;
          .sd-logo-img {
            height: 300px;
            margin-bottom: 0px;
          }
        }
      }
    }
    :nth-child(4) {
      display: none;
    }
    :nth-child(3) {
      display: none;
    }
  }
  .sd-template-four {
    height: 1000px !important;
    flex-wrap: wrap;
    padding: 30px !important;
    justify-content: center;

    .sd-t4-one {
      height: 420px !important;
      &:first-child {
        margin-right: 30px !important;
        margin-bottom: 30px;
      }
      width: 47% !important;
      .element-panchangam-wrapper {
        .element-panchangam {
        }
      }
    }
  }
  .sd-template-five {
    .sd-photo-grid {
      div {
        margin-bottom: 10px;
      }
    }
    .sd-photo-grid-full {
      div {
        width: 45%;
      }
    }

    button {
      font-size: 10px;
      border: none;
      padding: 1px 8px;
      margin-top: 10px;
      img {
        height: 8px;
        margin-left: 5px;
      }
    }
  }
  .sd-template-six {
    height: auto;
    padding: 30px;
    .sd-ts-bottom {
      flex-wrap: wrap;
      .sd-ts-inner-div {
        width: 50%;
        img {
          max-width: 150px;
        }
      }
      :nth-child(1) {
        background-color: #f1ad6f;
        border-radius: 0px;
        border-top-left-radius: 8px;
      }
      :nth-child(2) {
        background-color: #f3b782;
        border-radius: 0px;

        border-top-right-radius: 8px;
      }
      :nth-child(3) {
        background-color: #f5c396;
        border-radius: 0px;

        border-bottom-left-radius: 8px;
      }
      :nth-child(4) {
        background-color: #f6ccaa;
        border-radius: 0px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .sd-template-one {
    .sd-t1-slider {
      padding: 20px 10px;
      padding-bottom: 0px;
      overflow-x: scroll;
      &::-webkit-scrollbar-thumb {
        display: none;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      // flex-wrap: wrap;
      // justify-content: center;
      .temples-div {
        width: 20% !important;
        min-width: 21%;
        margin-left: 1.5%;
        img {
          border-radius: 12px !important;
        }
        p {
          font-size: 13px;
          margin-left: -8px;
        }
      }
      .slick-slider {
        .slick-dots {
          bottom: -18px;
          li {
            margin: 0 0px;
            width: 15px;
            color: #fd5602;
            button {
              opacity: 1;
              &::before {
                color: #fd5602;
                opacity: 1;
                font-size: 8px;
              }
            }
          }
          .slick-active {
            button {
              opacity: 1;
              &::before {
                color: #ffaf42;
                opacity: 1;
                font-size: 8px;
              }
            }
          }
        }
      }
    }
    hr {
      margin: 0px 15px;
    }
    .sd-t1-slider-caraousel {
      padding: 15px 15px 20px 15px;
      min-height: 100px;
      // padding: 6px 2px 12px 5px;
      .slick-slider {
        .slick-dots {
          bottom: -18px !important;
          li {
            margin: 0 2px;
            width: 15px;
            color: #620404;
            button {
              opacity: 1;
              &::before {
                color: #620404;
                opacity: 1;
                font-size: 11px !important;
              }
            }
          }
          .slick-active {
            button {
              opacity: 1;
              &::before {
                color: #ecbd00;
                opacity: 1;
                font-size: 11px !important;
              }
            }
          }
        }
      }
    }
    .sd-t1-bottom {
      height: 40px;
      padding-left: 10px;
      padding-right: 10px;
      .sd-orange {
        width: 24%;
        font-size: 11px;
        padding-right: 3px;
        text-align: center;
        margin-right: 0px !important;
        img {
          display: none;
        }
      }
      .sd-marquee-text {
        width: 45%;
        font-size: 12px;
      }
      div {
        width: 25%;
        button {
          font-size: 12px;
          img {
            height: 14px;
            margin-left: 5px;
            margin-top: 0px;
          }
        }
      }
    }
  }
  .sd-template-two {
    height: 1100px;
    padding: 20px 20px 20px 20px;
    flex-wrap: wrap;
    justify-content: space-around;
    .sd-box-one {
      width: 100%;
      padding: 30px;
      display: flex;
      margin: 2%;
      .sd-box-one-content {
        padding-left: 25px;
        padding-right: 25px;

        p {
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 15px;
        }
        button {
          font-size: 12px;
          img {
            height: 10px;
            margin-left: 7px;
          }
        }
        h2 {
          font-size: 16px;
          margin: 0px;
        }
        // border-left: 0.5px solid #dbdbdb;
      }
      .sd-box-one-image {
        height: 30%;
        .sd-logo-img {
          height: 60px;
          margin: 0px;
        }
      }
    }
  }
  .sd-template-three {
    height: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .sd-t3-one {
      width: 100%;
      margin-bottom: 25px;
      padding: 20px;
      align-items: center;
      p {
        text-align: center;
        margin: 15px 0px;
      }
      h1 {
        text-align: center;
      }
      img {
        width: 90% !important;
      }
      .nav-circles {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        img {
          width: 45px !important;
        }
      }
    }
    .sd-t3-three {
      width: 100%;
      max-width: 400px;
      .sd-box-one {
        margin-bottom: 20px;
        .sd-box-one-image {
          height: 280px;
          img {
            height: 280px !important;
          }
        }
        .sd-box-one-content {
          .sd-b-orange {
            font-size: 12px;
          }
          h2 {
            font-size: 16px;
          }
          p {
            font-size: 13px;
          }
        }
      }
    }
    .sd-t3-two {
      width: 100%;
      .sd-box-one {
        padding: 12px;
        .sd-box-one-content {
          .sd-b-orange {
            font-size: 12px;
          }
          h2 {
            font-size: 16px;
          }
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
  .sd-template-four {
    height: 1170px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .sd-t4-one {
      width: 100%;
      margin-bottom: 20px;
      max-height: 350px;
    }
  }
  .sd-template-five {
    height: auto;
    padding: 20px;
    h2 {
      font-size: 36px;
      // margin-bottom: 20px;
    }
    .sd-photo-grid {
      height: 90%;
      display: flex;
      flex-wrap: wrap;
      img {
        width: 100%;
      }
      div {
        flex-wrap: wrap;
        margin-bottom: 0vh;

        img {
          margin: 5px;
          margin-bottom: 10px;

          height: 41%;
          width: 46.5%;
        }
      }
    }
    .sd-photo-grid-full {
      div {
        width: 90%;
      }
    }
    button {
      font-size: 12px;
      padding: 2px 10px;
      margin-top: 10px;
      img {
        height: 10px;
        margin-left: 5px;
      }
    }
  }
  .sd-template-six {
    padding: 20px;
    height: auto;
    flex-direction: column;
    .sd-ts-bottom {
      flex-wrap: wrap;
      .sd-ts-inner-div {
        width: 50%;
        img {
          max-width: 150px;
        }
      }
      :nth-child(1) {
        background-color: #f1ad6f;
        border-radius: 0px;
        border-top-left-radius: 8px;
      }
      :nth-child(2) {
        background-color: #f3b782;
        border-radius: 0px;

        border-top-right-radius: 8px;
      }
      :nth-child(3) {
        background-color: #f5c396;
        border-radius: 0px;

        border-bottom-left-radius: 8px;
      }
      :nth-child(4) {
        background-color: #f6ccaa;
        border-radius: 0px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .sd-template-six {
    .sd-ts-bottom {
      flex-wrap: wrap;
      .sd-ts-inner-div {
        width: 100% !important;
        img {
          max-width: 150px;
        }
      }
      :nth-child(1) {
        background-color: #f1ad6f;
        border-radius: 0px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      :nth-child(2) {
        background-color: #f3b782;
        border-radius: 0px;
      }
      :nth-child(3) {
        background-color: #f5c396;
        border-radius: 0px;
      }
      :nth-child(4) {
        background-color: #f6ccaa;
        border-radius: 0px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }
  .sd-template-three {
    .sd-t3-three {
      max-width: 300px;
      .sd-box-one {
        margin-bottom: 20px;
        .sd-box-one-image {
          height: 255px;
          img {
            height: 250px !important;
          }
        }
      }
    }
  }
  .sd-template-two {
    height: 1150px !important;

    .sd-tt-left {
      display: none !important;
    }
  }
  .sd-template-one {
    .sd-t1-slider {
      .temples-div {
        width: 25% !important;
        min-width: 25%;
        margin-left: 1.5%;
        img {
          border-radius: 12px;
        }
        p {
          font-size: 13px;
          margin-left: -8px;
        }
      }
    }
  }
}
@media screen and (min-width: 100px) and (max-width: 350px) {
  .sd-template-one {
    .sd-t1-slider {
    }
    .sd-t1-bottom {
      .sd-marquee-text {
        width: 50%;
      }
      div {
        button {
          font-size: 12px;
          padding: 2px 10px;
          img {
            height: 9px;
            margin-left: 5px;
            margin-top: 0px;
          }
        }
      }
    }
  }
  .sd-template-two {
    height: 850px !important;

    .sd-tt-left {
      display: none;
    }
  }
  .sd-template-three {
    .sd-t3-three {
      width: 280px;
      max-width: 280px !important;
      .sd-box-one {
        .sd-box-one-image {
          height: 220px !important;

          img {
            height: 220px !important;
          }
        }
      }
    }
  }
  .sd-template-four {
    margin-bottom: 30px;
    .sd-t4-box-left {
      height: 400px;
    }
    .sd-t4-box-right {
    }
  }
  .sd-template-five {
    // height: 500px;
    padding: 20px;
    h2 {
      font-size: 36px;
      // margin-bottom: 20px;
    }
    .sd-photo-grid {
      height: 90%;
      div {
        flex-wrap: wrap;
        margin-bottom: 0vh;

        img {
          margin: 5px;
          margin-bottom: 1vh;

          height: 41%;
          width: 46%;
        }
      }
    }
    button {
      font-size: 12px;
      padding: 2px 10px;
      margin-top: 10px;
      img {
        height: 10px;
        margin-left: 5px;
      }
    }
  }
}

@keyframes circleAnimation {
  0% {
    stroke-dashoffset: 75;
  }

  50% {
    stroke-dashoffset: 280;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
