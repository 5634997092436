.sd-support-main {
  padding: 45px 60px;
  padding-bottom: 0px;
  .sd-support-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 30px;
      color: #991403;
      padding-bottom: 10px;
      letter-spacing: 0;
      font-weight: 600;
      text-align: center;
      span {
        font-weight: 600;
      }
    }
    img {
      height: 10px;
    }
    padding: 0px 40px 30px 30px;
  }
  .sd-support-bottom {
    // padding-left: 50px;
    // padding-right: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .sd-support-div {
      overflow: hidden;
      padding: 18px 18px 20px;
      margin-bottom: 30px;
      border: 1px solid #bfbfbf;
      border-radius: 4px;
      width: 270px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 10px;
      .head-image {
        width: 50px;
        padding: 10px 0 0px 0px;
        // height: 140px;
        border-radius: 4px;
        align-self: center;
      }
      .sd-spcl {
        width: 62px;
        padding-top: 5px;
      }
      h4 {
        text-align: center;
        color: #991403;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        padding: 15px 0 5px;
        cursor: pointer;
      }
      h5 {
        text-align: center;
        letter-spacing: 0.3px;
        font-size: 15px;
        font-weight: 400;
        color: #181818;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* number of lines to show */
        -webkit-box-orient: vertical;
        line-height: 20px;
        margin-bottom: 5px;
      }
      h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 5px 0 5px;
        font-size: 15px;
        font-weight: 600;
        color: #991403;
        margin-bottom: 0px;
        cursor: pointer;

        img {
          height: 11px;
          margin-left: 5px;
          // margin-top: 2px;
          filter: invert(1);
        }
      }
      button {
        margin-top: 10px;
        color: white;
        background-color: #991403;
        border: none;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        padding: 6px 13px;
        border-radius: 3px;
        font-weight: 600;
        letter-spacing: 0.3px;
        .imgs {
          height: 11px;
          margin-left: 5px;
          margin-top: 1.5px;
        }
      }
    }
  }
}
.sd-support-contact-us {
  width: 100%;
  display: flex;
  padding: 45px 60px;
  .sd-cs-left {
    width: 30%;
    border-right: 1px solid #cbcbcb;
    h3 {
      border-bottom: 1px solid #cbcbcb;
      padding: 5px 0px 20px 0px;
      margin-right: 30px;
    }
    .sd-info {
      .sd-label-group {
        padding: 20px 30px 10px 0px;
        display: flex;

        .sd-image {
          text-align: right;
          width: 40px;
          margin-right: 0px;
          padding-left: 0px;
          margin-top: 5px;
          .sd-mail {
            width: 35px;
            height: 25px;
          }
          .sd-chat {
            width: 37px;
            height: 32px;
          }
          .sd-phone {
            width: 40px;
            height: 40px;
          }
          .sd-map {
            width: 30px;
            height: 35px;
          }
        }
        div {
          padding-left: 20px;
          flex-wrap: wrap;
          h4 {
            font-size: 18px;
            margin-bottom: 5px;
          }
          .fw400 {
            font-weight: 400 !important;
          }
          .fw600 {
            font-weight: 600 !important;
          }
          h5 {
            font-weight: 400;
            line-height: 24px;
            font-size: 17px;
            flex-wrap: wrap;
            color: #181818;
            word-wrap: normal;
            word-break: break-word;
          }
          .react-tel-input {
            margin-bottom: 10px;
            .selected-flag.open:after {
              border: none;
              box-shadow: none;
            }
            .selected-flag.open:before {
              border: none;
              box-shadow: none;
            }
            .country-list {
              margin: 2px 0px 0px 0px;
              border-radius: 3px;
            }
            input {
              padding: 0px;
              margin-bottom: 0px;
              width: 100%;
              padding: 18.5px 14px 18.5px 60px;
            }
          }
          .sd-phone-voilet {
            border: 1px solid #2f3293;
            border-radius: 4px;
            input {
              background: white !important;
              background-color: white !important;
            }
            input:hover {
              border: none;
            }
          }
          .sd-voilet-text {
            color: #991403;
            cursor: pointer;
          }
          form {
            margin-top: 10px;

            input {
              background-color: white;
              padding: 3px 8px;
              border: 1px solid #991403;
              color: #991403;
            }
          }
        }
        .react-tel-input {
          margin-bottom: 10px;
          .selected-flag.open:after {
            border: none;
            box-shadow: none;
          }
          .selected-flag.open:before {
            border: none;
            box-shadow: none;
          }
          .country-list {
            margin: 2px 0px 0px 0px;
            border-radius: 3px;
          }
          input {
            padding: 0px;
            margin-bottom: 0px;
            width: 100%;
            padding: 18.5px 14px 18.5px 60px;
          }
        }
        .sd-phone-voilet {
          border: 1px solid #2f3293;
          border-radius: 4px;
          input {
            background: white !important;
            background-color: white !important;
          }
          input:hover {
            border: none;
          }
        }
      }
    }
  }
  .sd-voilet {
    border: 1px solid #2f3193 !important;
    background-color: white !important;
  }

  .react-tel-input {
    .react-tel-input .form-control {
      padding: 5px 14px 5px 60px !important;
    }
    margin-bottom: 10px;
    .selected-flag.open:after {
      border: none;
      box-shadow: none;
    }
    .selected-flag.open:before {
      border: none;
      box-shadow: none;
    }
    .country-list {
      margin: 2px 0px 0px 0px;
      border-radius: 3px;
    }
    input {
      padding: 0px;
      margin-bottom: 0px;
      width: 100%;
      padding: 18.5px 14px 18.5px 60px;
    }
  }
  .sd-phone-voilet {
    border: 1px solid #2f3193;
    border-radius: 4px;
    input {
      background: white !important;
      background-color: white !important;
    }
    input:hover {
      border: none;
    }
  }
  .sd-cs-right {
    padding-left: 60px;
    width: 70%;
    .mobile {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: white;
      height: 42px;
      margin-bottom: 20px;
      .input-box {
        display: flex;
        align-items: center;
        max-width: 100%;
        border-radius: 4px;
        padding-left: 0.5rem;
        overflow: hidden;
        width: 100%;
        background-color: #eaeaea;
      }

      .input-box .prefix {
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        font-weight: 300;
        font-size: 14px;
        color: #999;
        img {
          padding-right: 10px;
        }
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }

      .input-box input {
        flex-grow: 1;
        font-size: 14px;
        border: none;
        outline: none;
        padding: 0.5rem;
        padding-left: 10rem;
        margin-bottom: 0px;
        background-color: #eaeaea;
        box-shadow: none;
        outline: none;
      }

      .input-box:focus-within {
        border: 1px solid #2f3293;
      }
      .voilet {
        border: 1px solid #2f3293;
        background-color: white !important;
        .PhoneInputCountry {
          background-color: white;
        }
        input {
          background-color: white;
        }
      }
    }
  }
  margin-bottom: 15px;
  // border-bottom: 1px solid #cbcbcb;
}
.sd-support-table-div {
  width: calc(100% - 120px);
  margin: 45px 60px;
  padding: 20px;
  box-shadow: 2px 4px 10px #00000029;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  .input {
    width: 300px;
    border: 2px solid #dcdcdc;
    border-radius: 2px;
    position: relative;
    input {
      width: 100%;
      padding: 11px 17px;
      outline: none;
      border: none;
      height: 100%;
      font-size: 16px;
    }
    img {
      position: absolute;
      right: 10px;
      top: 12px;
      height: 15px;
    }
  }
  table {
    border-collapse: collapse;
    margin-bottom: 50px;
    width: 100%;

    th {
      background-color: #ecf0f5;
      padding: 30px 15px;
      border: 1px solid #cdd7e2;
      color: #707070;
      &:first-child {
        width: 5%;
        max-width: 30px;
      }
      &:nth-child(2) {
        width: 30%;
      }
    }

    td {
      border: 1px solid #cdd7e2;
      padding: 10px 15px;
      font-size: 14px;
      color: #161a1e;
    }

    tr:first-child th {
      border-top: 0;
    }
    tr:last-child td {
      border-bottom: 0;
    }
    tr td:first-child,
    tr th:first-child {
      border-left: 0;
    }
    tr th:last-child {
      border-right: 0;
    }
    tr td:last-child {
      border-right: 0;
    }
  }
}
input {
  text-transform: capitalize;
}

@media screen and (min-width: 1600px) {
  .sd-support-main {
    .sd-support-top {
    }
    .sd-support-bottom {
      justify-content: center;
      .sd-support-div {
        width: 350px;
        margin: 10px;
        .head-image {
          // height: 170px;
        }
        .sd-timings {
          width: 350px;
        }
      }
    }
  }
  .sd-support-contact-us {
    padding-top: 90px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
  .sd-support-main {
    padding: 30px;
    .sd-support-top {
    }
    .sd-support-bottom {
      .sd-support-div {
        margin-bottom: 20px;
        width: 400px;
        .head-image {
          // height: 180px;
        }
        .sd-timings {
          width: 180px;
          // margin-right: -6%;
        }
      }
    }
  }
  .sd-support-contact-us {
    padding: 30px;
  }
}
@media screen and (max-width: 900px) and (min-width: 650px) {
  .sd-support-main {
    padding: 30px;
    .sd-support-top {
    }
    .sd-support-bottom {
      // padding-left: 30px;
      // padding-right: 30px;

      .sd-support-div {
        width: 270px;
        margin-bottom: 20px;
        .sd-timings {
          width: 270px;
          // width: 118%;
          // width: 118%;
          // margin-left: -8%;
          h3 {
            font-size: 13px;
          }
        }
      }
    }
  }
  .sd-support-contact-us {
    padding: 30px;
    flex-direction: column;
    .sd-cs-left {
      width: 100%;
      display: flex;
      padding-bottom: 30px;
      flex-direction: column;
      border-right: none;
      border-bottom: 1px solid #cbcbcb;
      .sd-info {
        display: flex;
        flex-wrap: wrap;
        .sd-label-group {
          width: 50%;
          display: flex;
          div {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .sd-cs-right {
      margin-top: 30px;
      width: 100%;
      padding-left: 10px;
      padding-bottom: 30px;
    }
  }
}
@media screen and (min-width: 420px) and (max-width: 650px) {
  .sd-support-table-div {
    width: calc(100% - 60px);
    margin: 30px !important;
    table {
      &:nth-child(2) {
        width: auto !important;
      }
      tr {
        td {
          word-break: break-word;
          &:first-child {
            word-break: keep-all;
          }
        }
      }
    }
  }
  .sd-support-main {
    padding: 30px;
    .sd-support-top {
    }
    .sd-support-bottom {
      padding-left: 0px;
      padding-right: 0px;

      .sd-support-div {
        width: 320px;
        margin-bottom: 20px;
        .sd-timings {
          width: 320px;
          // width: 118%;
          // width: 118%;
          // margin-left: -8%;
          h3 {
            font-size: 13px;
          }
        }
        button {
          .imgs {
            height: 11px;
            margin-left: 5px;
            margin-top: 1.5px;
          }
        }
      }
    }
  }
  .sd-support-contact-us {
    padding: 30px;
    flex-direction: column;
    .sd-cs-left {
      width: 100%;
      display: flex;
      padding-bottom: 30px;
      flex-direction: column;
      border-right: none;
      border-bottom: 1px solid #cbcbcb;
      .sd-info {
        display: flex;
        flex-wrap: wrap;
        .sd-label-group {
          width: 100%;
          display: flex;
          div {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .sd-cs-right {
      margin-top: 30px;
      width: 100%;
      padding-left: 10px;
      padding-bottom: 30px;
    }
  }
}
@media screen and (max-width: 420px) {
  .sd-support-main {
    padding: 30px;
    .sd-support-top {
    }
    .sd-support-bottom {
      justify-content: center;
      padding: 0px;

      .sd-support-div {
        width: 244px;
        .sd-timings {
          width: 244px;
        }
      }
    }
  }
  .sd-support-contact-us {
    padding: 30px;
    flex-direction: column;
    .sd-cs-left {
      width: 100%;
      display: flex;
      padding-bottom: 30px;
      flex-direction: column;
      border-right: none;
      border-bottom: 1px solid #cbcbcb;
      h3 {
        text-align: center;
        width: 100%;
      }
      .sd-info {
        display: flex;
        flex-wrap: wrap;
        .sd-label-group {
          width: 100%;
          display: flex;
          padding-right: 10px;
          div {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .sd-cs-right {
      margin-top: 30px;
      width: 100%;
      padding-left: 0px;
      padding-bottom: 30px;
    }
  }
}
