@font-face {
  font-family: "Proxima Nova Alt";
  src: local("Proxima Nova Alt"),
    url(./Utils/fonts/Proximal/FontsFree-Net-ProximaNovaAltRegular.ttf)
      format("truetype");
  font-display: fallback;
}
@font-face {
  font-family: "Proxima Nova Alt";
  src: local("Proxima Nova Alt"),
    url(./Utils/fonts/Proximal/FontsFree-Net-Proxima-Nova-Bold.otf)
      format("truetype");
  font-weight: 700;
  font-display: fallback;
}
@font-face {
  font-family: "Proxima Nova Alt";
  src: local("Proxima Nova Alt"),
    url(./Utils/fonts/Proximal/FontsFree-Net-Proxima-Nova-Sbold.otf)
      format("truetype");
  font-weight: 600;
  font-display: fallback;
}
body {
  margin: 0;
  font-family: "Proxima Nova Alt", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  color: #181818;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}
body::-webkit-scrollbar {
  display: none;
}
html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-display: fallback;
}
.rc-anchor-invisibler {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 600;
}
.sd-imp {
  color: red;
  padding-left: 3px;
}
